@import './_constants.scss';

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @mixin absXCenter {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @mixin absYCenter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  @mixin pseudo {
    content: '';
    display: inline-block;
  }

  @mixin selectArr {
    content: '';
    display: inline-block;
    border: 5px solid transparent;
    border-top: 5px solid #72706B;
    margin-bottom: -3px;
    margin-left: 1rem;
  }

  @mixin clearFix {
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }



@mixin font-size($selector, $size-key) {
  font-size: map-get(map-get($font-sizes, $size-key), $selector);
}

@mixin line-height($selector, $size-key) {
  line-height: map-get(map-get($line-heights, $size-key), $selector);
}

  //MEDIA QUERY MANAGER
  @mixin respond($breakpoint) {
    @if $breakpoint==xxxl {
      @media screen and (max-width: 1949.9px) {
        @content;
      }
    }
    @if $breakpoint==xxl {
      @media screen and (max-width: 1799.9px) {
        @content;
      }
    }

    @if $breakpoint==xl {
      @media screen and (max-width: 1499.9px) {
        @content;
      }
    }

    @if $breakpoint==xlg {
      @media screen and (max-width: 1399.9px) {
        @content;
      }
    }

    @if $breakpoint==lg {
      @media screen and (max-width: 1199.9px) {
        @content;
      }
    }

    @if $breakpoint==md {
      @media screen and (max-width: 991.9px) {
        @content;
      }
    }

    @if $breakpoint==sm {
      @media screen and (max-width: 768px) {
        @content;
      }
    }

    @if $breakpoint==xs {
      @media screen and (max-width: 575.9px) {
        @content;
      }
    }

    @if $breakpoint==xsx {
      @media screen and (max-width: 499.9px) {
        @content;
      }
    }

    @if $breakpoint==xss {
      @media screen and (max-width: 359.9px) {
        @content;
      }
    }
  }
