@import './_mixins.scss';



.hidden-mobile {
    @include respond(sm) {
        display: none !important;
    }
}

.hidden-desktop {
    display: none !important;

    @include respond(sm) {
        display: inline-block !important;
    }
}


.icon {
    width: 1.5rem;
    height: 1.5rem;
}

.tabs__content {
    display: none !important;
}

.show {
    display: block !important;
}
.customWidth {
    width: 450px !important;
    margin: 40px 0 !important;
    word-wrap: break-word;
}
.customWidth #body {
    width: 600px !important;
    height: auto !important;
}
.customWidth input {
    height: 40px !important;
    background: #f8f8f8;
} 
.RaFileInput-dropZone {
    height: 200px;
    width: 200px;
}
.editForm label span, .editForm .RaLabeled-label span, .editForm .RaArrayInput-label span {
    font-size: 20px !important;
}
.fileInput .RaFileInput-dropZone {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
}
.editForm .RaSimpleFormIterator-buttons .MuiSvgIcon-root {
    font-size: 4rem !important;
}
.MuiTypography-root {
    font-size: 2rem !important;
}
.edit-page img {
    width: 300px !important;
}

/* Стили для неактивного скролла */
::-webkit-scrollbar-thumb {
    opacity: 0;
    transition: opacity 0.5s ease; /* Добавляем плавный переход для эффекта исчезновения */
}

/* Остальные стили для полосы прокрутки */
::-webkit-scrollbar {
    width: var(--scrollbar-width)
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    background-color: rgba(42, 42, 42, 0.2);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: #000000;
}

.baseFlexWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
}

.baseFlexColumn {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.fileInput{
    width: 20rem !important;
}