@import '../../../style/mixins';

.contacts {
    &-main {
        padding: 13.4rem 0 4rem;
        color: #050505;
        position: relative;
        overflow: hidden;

        @include respond(sm){
            padding: 11.4rem 0 3rem;
        }

        &__text{
            display: flex;
            flex-direction: column;
            p{
                color: #757677;
            }
        }

    }

    &-info{
        position: relative;
        padding: 0 0 7rem 0;
        @include respond(lg){
            padding: 0 0 6rem 0;
        }
        @include respond(sm){
            padding: 0 0 3rem 0;
        }
        &__wrap {
            display: grid;
            grid-template-columns: 1fr 1fr ;
            @include respond(xs){
                grid-template-columns: 1fr;
                gap: 1rem;
            }

            &-invate{
                display: flex;
                flex-direction: column;
                gap: 2.8rem;
                @include respond(lg){
                    gap: 2rem;
                }

                @include respond(xs){
                    display: grid;
                    grid-template-columns: 1fr 1fr ;
                }


            }

            p{
                margin-top: .6rem;
                color: #757677;
            }

        }
    }


    &-general{
        overflow: hidden;
        padding:  7rem 0 0 0;
        @include respond(lg) {
            padding:  6rem 0 0 0;
        }
        @include respond(sm) {
            padding:  3rem 0 0 0;
        }

        .container {
            @include respond(sm) {
                padding: 0 2rem;
            }
        }

        .btn {
            display: inline-block;
            flex-direction: column;
            text-align: center;
            font-size: 2rem;
            padding: 1.3rem 3rem ;
            gap: 2rem;
            margin-top: 11.1rem;
            @include respond(sm) {
                gap: 1.5rem;
                font-size: 1.6rem;
                margin: 0;
            }


        }

        &__wrap {
            padding-bottom: 7rem;
            background-color: #FFFFFF;
            display: grid;
            grid-template-columns: 1fr 1fr;

            @include respond(xs) {
                padding-bottom: 0;
                grid-template-columns: 100%;
                grid-gap: 0;
            }

            .chat {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: baseline;
                justify-content: space-between;
            }

            .info {
                .heading-secondary {
                    @include respond(sm) {
                        font-size: 1.6rem;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
                .worker {
                    margin-top: 5rem;
                    display: flex;
                    align-items: center;
                    gap: 3rem;
                    @include respond(sm) {
                        margin: 2rem 0 3rem;
                        gap: 2rem;
                    }

                    &-img {
                        border: 1px solid #FFFFFF;
                        border-radius: 50%;
                        width: 5rem;
                        height: 5rem;

                        @include respond(sm) {
                            width: 7rem;
                            height: 7rem;
                        }

                    }

                    .post {
                        opacity: .5;
                    }
                }


            }


        }
    }

}

.popup-content {
    margin: auto;
    padding: 10px;
    border-radius: 8px;

    button {
        margin: auto;

    }

}

.popup-overlay {
    background: rgb(0 0 0 / 79%);
    z-index: 9999 !important;
}

.hidden-desk {
    display: none !important;
    @include respond(xs) {
        display: inline-block !important;
    }
}

.hidden-mob {
    display: inline-block !important;
    @include respond(xs) {
        display: none !important;
    }
}

.btnTg {
    overflow: hidden;
    cursor: pointer;
    position: relative;
    font-size: 1.6rem;
    padding: 1.1rem 2rem;
    border-radius: 9.4rem;
    background-color: #E0FD60;
    @include respond(lg){
        padding: 1rem 2rem;
    }
    @include respond(xs){
        padding: 1.1rem 2rem;
    }

    p{
        margin-right: 2rem;
    }

    .icon {
        transition: all .3s ease-out;
        width: 2.4rem;
        height: 2.4rem;
        right: 1rem;
        left: auto;
        position: absolute;
        top: 0;
        opacity: 0;
        transform: scale(0);
        transform: translateX(1rem);

    }

    &:hover {
        .icon {
            top: .7rem;
            opacity: 1;
            transform: scale(1);
            transform: translateX(0);
        }
    }

}

h2 {
    .hoverMail {
        transition: all 0.3s ease;
        opacity: 1;
        &:hover {
            transition: all 0.3s ease;
            opacity: .5;
        }
    }
}

.contacts-cursor {
    z-index: 5000;
}
