@import '../../style/mixins';

.news {
    color: #050505;
    &-start {
        padding: 13.4rem 0 4rem;
        position: relative;
        overflow: hidden;

        @include respond(sm){
            padding: 11.4rem 0 3rem;
        }

        &__text{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            @include respond(sm){
                gap: 1rem;
            }

            p{
                color: #757677;
            }
        }

    }

    &-main {
        &__filters {
            background-color: white;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 1rem 0;

            .active{
                .news-main__filters-btn__flexWrap  {
                    background-color: #050505;
                    color: white;

                }
                .num {
                    background-color: #E0FD60;
                    color: #050505;
                    border-color: inherit;
                }
            }

            &-btn {
                cursor: pointer;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                &__flexWrap {
                    display: flex;
                    flex-wrap: nowrap;
                    background-color: rgba(5, 5, 5, 0.04);
                    padding: .9rem 1.5rem .9rem 1.5rem;
                    border-radius: 3.5rem;
                    align-items: center;
                    transition: all .2s ease-out;

                    &:hover{
                        .num {
                            background-color: #E0FD60;
                            color: #050505;
                            border-color: inherit;
                        }
                        background-color: #050505;
                        color: white;
                    }

                    .name {
                        margin-right: .5rem;
                    }
                }

                &::after {
                    content: "/";
                    color: #757677;
                    margin: 0 1rem;
                    font-size: 2rem;
                    line-height: 150%;
                    opacity: 0.2;
                    @include respond(xs) {
                        margin: 0 .5rem;
                    }
                }
            }
        }

        &__wrap {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-auto-rows: auto;
            grid-gap: 4rem;

            .flex-wrap{
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }

            @include respond(lg) {
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 3rem;
            }

            @include respond(sm) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 3rem 2rem;
            }

            @include respond(xs){
                grid-template-columns: 100%;
                grid-gap: 3rem;
            }
            .filter {
                grid-row: 1 / -1;
                grid-column: 3 / 5;
                @include respond(lg){
                    grid-column: 1 / -1; /* Span across all columns */
                    grid-row: 1;
                }
            }
            .news-main__item:nth-child(1), .news__item:nth-child(2) {
                grid-row: 1;
            }
            .news-main__item:nth-child(n+3) {
                grid-row: 1;
            }

        }

        &__item {
            position: relative;
            overflow: hidden;
            border-radius: 2rem;
            max-height: 20.6rem;
            height: 100%;
            width: 100%;
            // cursor: url('../../img/newsHover.svg'), default !important;

            @include respond(lg){
                max-height: 19.875rem;
            }

            @include respond(sm){
                max-height: 21.2rem;
            }

            .news__descr {
                color: rgba(5, 5, 5, 0.02);
            }

            .news__img-wrap {
                transition: all 0.3s ease-in-out;
                width: 100%;
                display: block;
                height: 100%;
                object-fit: cover;
            }

            img, video {
                transition: all 0.3s ease-in-out;
                width: 100%;
                display: block;
                height: 100%;
                object-fit: cover;
            }

        }

        &__text {
            color: #757677;
        }

        &__descr {

        }

    }

}

.num {
    display: flex;
    line-height: normal;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #757677;
    color: #757677;
    gap: 1rem;
    border-radius: 39px;
    padding: 6px 10px 5px 10px;
    transition: all 0.2s ease-out;


}