@font-face {
    font-family: "Graphik LC Web Regular";
    src: url("https://db.onlinewebfonts.com/t/575fc498ad34ad0f411e618dad9ed36a.eot");
    src: url("https://db.onlinewebfonts.com/t/575fc498ad34ad0f411e618dad9ed36a.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/575fc498ad34ad0f411e618dad9ed36a.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/575fc498ad34ad0f411e618dad9ed36a.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/575fc498ad34ad0f411e618dad9ed36a.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/575fc498ad34ad0f411e618dad9ed36a.svg#Graphik LC Web Regular")format("svg");
    font-weight: 400;
}

@font-face {
    font-family: "Graphik LC Web Medium";
    src: url("https://db.onlinewebfonts.com/t/e81d1bf7827206c7d1273046adace7f2.eot");
    src: url("https://db.onlinewebfonts.com/t/e81d1bf7827206c7d1273046adace7f2.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/e81d1bf7827206c7d1273046adace7f2.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/e81d1bf7827206c7d1273046adace7f2.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/e81d1bf7827206c7d1273046adace7f2.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/e81d1bf7827206c7d1273046adace7f2.svg#Graphik LC Web Medium")format("svg");
    font-weight: 500;
}