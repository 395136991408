@import './_mixins.scss';

a:hover {
    text-decoration: none;
}

*,
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
object,
fieldset,
abbr,
textarea {
    margin: 0;
    padding: 0;
    outline: 0;
}

div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
img,
small,
ol,
ul,
li {
    border: none;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: inherit;
}

img,
video {
    max-width: 100%;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
}

input {
    font-family: inherit;
    font-size: inherit;
    color: inherit;

    &::placeholder {
        color: inherit;
    }
}

html {
    font-size: 10px;
    scroll-behavior: smooth;

    @media (width >= 2000px) {
        font-size: 16px;
    }
    //@include respond(xxxl) {
    //    font-size: 10px;
    //}
    //
    //@include respond(xxl) {
    //    font-size: 8px;
    //}
    //
    //@include respond(xl) {
    //    font-size: 7.5px;
    //}
    //
    //@include respond(xlg) {
    //    font-size: 6.6px;
    //}
    //
    //@include respond(lg) {
    //    font-size: 6.08px;
    //}
    //
    //@include respond(sm) {
    //    font-size: 10px;
    //}
    //
    //@include respond(xss) {
    //    font-size: 8px;
    //}
}

.container {
    // max-width: 250rem;
    // max-width: 1920px;
    max-width: 1840px;
    width: 100%;
    height: 100%;
    padding: 0 4rem;
    margin-right: auto;
    margin-left: auto;
    @include respond(lg) {
        padding: 0 3rem;
    }
    @include respond(sm){
        padding: 0 2rem;
    }
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    animation: fadeIn 0.3s ease 0.3s forwards;
}

main {
    animation: fadeIn 0.3s ease 0.3s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


#root {
    min-height: 100vh;
    background: #EEEEEE;
    display: flex;
    flex-direction: column;
}

.wrapper {
    max-width: 1800px;
    margin: 0 auto;
    width: 100%;
}

// .custom-cursor {
//     opacity: 1;
//     background: #ffffff;
//     border-radius: 50%;
//     position: absolute;

//     @include respond(sm) {
//         display: none;
//     }
// }
// .custom-circle-cursor {
//     background-color: #fff;
//     border-radius: 50%;
// }
.showreel__s:hover{

    .custom-cursor {
        cursor: none !important;
    }

}

.cursor--large {
    background:#ff0000 !important;
}

.js-cursor-play {
    cursor: none;
}

#preloader {
    width: 100%;
    height: 120%;
    background-color: #191B1D;
    position: fixed;
    top: -20%;
    left: 0;
    z-index: 9999;
    -webkit-clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
    -webkit-transition: opacity .7s, transform .7s;
    transition: opacity .7s, transform .7s;
}

#preloader .loaded {
    transform: translateY(120%);
}

//#preloader {
//    position: fixed;
//    left: 0;
//    z-index: 900;
//    width: 100%;
//    height: 100%;
//    background: #ffffff;
//    animation: hidePreloader 1s ease-in-out ;
//    transition: transform 0.9s ease-in-out 0s, visibility 1.1s ease-in-out 0s, color 0.4s ease-in-out 0s;
//}
//
//#preloader.preloader-hidden {
//}
//
//#preloader svg {
//    width: 100%;
//    animation: bigSVG 1.5s ease-in-out infinite;
//}
//
//#preloader svg path {
//    animation: colorSVG 1.5s ease-in-out infinite;
//}
//
//@keyframes bigSVG {
//    0% {
//        height: 0;
//    }
//
//    100% {
//        height: 50%;
//    }
//}
//
//@keyframes colorSVG {
//    0% {
//       fill: none;
//    }
//
//    50% {
//        fill: rgb(0, 0, 0);
//    }
//
//    85% {
//        fill: rgba(255, 255, 255, 0);
//    }
//
//    100% {
//     fill: none;
//    }
//}

.select {
    display: flex;
    align-items: center;
    padding: 5rem 0;
    background-color: transparent;
    //border: 1px solid rgba(255, 255, 255, 0.2);
    //border-radius: 45px;
    cursor: pointer;
    font-size: 2.8rem;

    line-height: 2.6rem;
    color: #ffffff;
    transition: border-color .3s;

    @include respond(sm) {
        justify-content: space-between;
    }

    &.active {
        @include respond(sm) {
            &:nth-child(n+2) {
                margin-bottom: 1rem;
            }
        }
        .select__icon {
            transform: rotate(180deg) translateX(-1.3rem);
        }
    }

    &:hover {
        border-color: rgba(25, 27, 29, 0.5);
    }

    &__icon {
        padding-left: 1rem;
        transition: transform .3s;
        display: none;

        [class$='indicatorContainer'] {
            padding: 0;
            color: rgba(25, 27, 29, .6);
        }
    }

    &__dropdown {
        min-width: 100%;
        white-space: nowrap;
        padding: 2.5rem 3rem;
        background: rgba(255, 255, 255, 0.4);
        border: 1px solid #191B1D;
        backdrop-filter: blur(10px);
        border-radius: 25px;
        position: relative;
        right: 0;
        top: calc(100% + 1rem);
        z-index: 5;
        overflow: hidden;
        cursor: pointer;
        @include respond(sm) {
            justify-content: space-between;
            position: relative;
        }
    }

    &__item {
        font-size: 2rem;
        line-height: 2.4rem;
        cursor: pointer;
        transition: color .3s;

        &:hover {
            color: #FF4D01;
        }

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    &__search {
        opacity: 0;
        height: 0;
        position: absolute;
    }
}

.popup {
    &-video-content {
        width: 100%;
        height: 100%;
    }

    &__close {
        cursor: pointer;
        font-size: 10rem;
        color: #fff;
        line-height: 5rem;
        position: absolute;
        top: -4rem;
        right: -4rem;
        z-index: 5;

        @include respond(sm) {
            top: 4rem;
            right: 4rem;
            font-size: 8rem;
            line-height: 4rem;
        }
    }

    &__content {
        height: 100%;
    }

    &__video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &-content {
        max-width: 80%;
        max-height: 80%;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        button {
            position: absolute;
            top: -1rem;
            right: -32rem;
            background: none;
            border: none;
            cursor: pointer;

            @include respond(lg) {
                position: absolute;
                top: -2rem;
                right: -35rem;
                background: none;
                border: none;
                cursor: pointer;

            }

            @include respond(sm) {
                position: absolute;
                top: -4rem;
                right: -8rem;
                background: none;
                border: none;
                cursor: pointer;
            }

            @include respond(xs) {
                position: absolute;
                //top: -3rem;
                //right: -2rem;
                background: none;
                border: none;
                cursor: pointer;

            }

            @include respond(xsx) {
                position: absolute;
                top: -3rem;
                right: -1rem;
                background: none;
                border: none;
                cursor: pointer;

            }

            @include respond(xss) {
                position: absolute;
                top: -3rem;
                right: 0rem;
                background: none;
                border: none;
                cursor: pointer;
            }


            svg {
                width: 4rem;
                height: 4rem;
                fill: #fff;
            }
        }
    }
}

.MuiCardContent-root {
    margin-bottom: 100px !important;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.hover-flip {
    cursor: pointer;
    overflow: hidden;
    height: auto;
}

.hover-flip span {
    display: inline-block;
    position: relative;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.hover-flip span::before {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 120%;
    content: attr(data-hover);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.hover-flip:hover span {
    -webkit-transform: translateY(-120%);
    transform: translateY(-120%);
}

//arrow

.hover-flip-arrow span {
    display: inline-block;
    transform: none;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.hover-flip-arrow + .hover-double {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.hover-flip-arrow {
    overflow: hidden;
    width: 3rem;
    height: 3rem;
    @include respond(xs) {
        height: 2.1rem;
        width: 2.1rem;
    }
}

.hover-flip-circle {
    width: 2.143rem;
    height: 2.143rem;

}

.hover-flip-arrow span,
.hover-flip-circle span {
    display: block;
    position: relative;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.hover-flip-arrow span{
    transform: translatex(-100%) translateY(-100%);
}

.hover-flip-circle span {
    transform: translatex(-115%) translateY(-115%);
}





.hover-flip-arrow:hover span {
    transform: translatex(0%) translatey(0%) ;
}
.hover-flip-circle:hover span{
    transform: translatex(15%) translatey(15%) ;
}

.hover-double {
    width: 3rem;
    height: 3rem;
    transition: transform 0.3s;
    position: absolute;
    top: 100%;
    left: 100%;
    @include respond(sm) {
        width: 2.1rem;
        height: 2.1rem;
    }
}

.hover-circle {
    transform: translate(15%, 15%);
    transition: transform 0.3s, -webkit-transform 0.3s;
    position: absolute;
    top: 100%;
    left: 100%;
}

.borderBlock {
    border-radius: 5rem;
    @include respond(lg) {
        border-radius: 4rem;
    }
    @include respond(sm) {
        border-radius: 3rem;
    }

    @include respond(xs) {
        border-radius: 2rem;
    }
}

.padding {
    padding: 4rem;
    @include respond(lg) {
        padding: 3rem;
    }
    @include respond(xs) {
        padding: 2rem;
    }
}

.breadcrumb {
    font-size: 1.6rem;
    color: #757677;
    padding-bottom: 2rem;
    @include respond(sm){
        padding-bottom: 1rem;
    }
}