@import '../../../../style/mixins';

.project-next {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    @include respond(lg){
        gap: 3rem;
    }
    @include respond(sm){
        gap: 2rem;
    }
    &__wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: auto;
        gap: 4rem;
        @include respond(lg){
            gap: 2rem;
        }
        @include respond(xs) {
           display: flex;
            width: 100vw;
            gap: 1rem;
            flex-wrap: nowrap;
            overflow-x: auto;
            margin-left: -2rem;
            padding:  0 2rem;

            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }



    &__item {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        @include respond(xs){
            flex-shrink: 0;
            min-width: 32rem;
        }

    }
    video {
        //cursor: url('../../../../img/videoCursor.svg'), default !important;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__text {
    }

    &__subtitle {
        margin-bottom: 2rem;
        line-height: 1.8rem;

        @include respond(sm) {
            margin-bottom: 1rem;
        }
    }

    &__name {
        max-width: 43rem;
        margin-bottom: 4rem;
        font-weight: 500;
        font-size: 3.6rem;
        line-height: 4.3rem;

        @include respond(sm) {
            margin-bottom: 2rem;
            font-size: 3.2rem;
            line-height: 3.8rem;
        }
    }
}