@import './_mixins.scss';
@import './_constants.scss';

body {
    font-family: "Graphik LC Web Regular", sans-serif !important;
    font-size: 1.2rem;
    line-height: 1.2;
    color: #050505;
}

.heading-primary {
    //color: #121212;
    font-weight: 400;
    letter-spacing: -0.03rem;
    @include respond(xxxl){
        @include font-size('h1', 'xxxl');
        @include line-height('h1', 'xxxl');
    }
    @include respond(sm){
        @include font-size('h1', 'sm');
        @include line-height('h1', 'sm');
    }
}

.heading-secondary {
    font-weight: 400;
    letter-spacing: -0.03rem;
    @include respond(xxxl){
        @include font-size('h2', 'xxxl');
        @include line-height('h2', 'xxxl');
    }
    @include respond(sm){
        @include font-size('h2', 'sm');
        @include line-height('h2', 'sm');
    }

}

.heading-thirty {
    font-weight: 400;
    letter-spacing: -0.03rem;
    @include respond(xxxl){
        @include font-size('h3', 'xxxl');
        @include line-height('h3', 'xxxl');
    }
    @include respond(sm){
        @include font-size('h3', 'sm');
        @include line-height('h3', 'sm');
    }
}

.l-textMed {
    font-family: "Graphik LC Web Medium", sans-serif;
    @include respond(xxxl){
        @include font-size('body-L', 'xxxl');
        @include line-height('body-L-Med', 'xxxl');
    }
    @include respond(sm){
        @include font-size('body-L', 'sm');
        @include line-height('body-L-Med', 'sm');
    }
}

.l-textReg {
    font-weight: 400;
    @include respond(xxxl){
        @include font-size('body-L', 'xxxl');
        @include line-height('body-L-Reg', 'xxxl');
    }
    @include respond(sm){
        @include font-size('body-L', 'sm');
        @include line-height('body-L-Reg', 'sm');
    }
}

.m-text {
    font-weight: 400;
    @include respond(xxxl){
        @include font-size('body-M', 'xxxl');
        @include line-height('body-M', 'xxxl');
    }
    @include respond(sm){
        @include font-size('body-M', 'sm');
        @include line-height('body-M', 'sm');
    }
}

.s-text {
    font-weight: 400;
    @include respond(xxxl){
        @include font-size('body-S', 'xxxl');
        @include line-height('body-S', 'xxxl');
    }
    @include respond(sm){
        @include font-size('body-S', 'sm');
        @include line-height('body-S', 'sm');
    }
}

.xs-text {
    font-weight: 400;
    @include respond(xxxl){
        @include font-size('body-XS', 'xxxl');
        @include line-height('body-XS', 'xxxl');
    }
    @include respond(sm){
        @include font-size('body-XS', 'sm');
        @include line-height('body-XS', 'sm');
    }
}
