@import '../../../style/mixins';

.projects {
    &-start {
        padding: 13.4rem 0 6.1rem;
        color: #050505;
        position: relative;
        overflow: hidden;

        @include respond(lg){
            padding: 13.4rem 0 3rem;
        }
        @include respond(sm){
            padding: 11.4rem 0 3rem;
        }

        &__text{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            @include respond(sm){
                gap: 1rem;
            }
        }

        &__filters {
            position: relative;
            display: inline-block;
            border-radius: 5.2rem;
            background: rgb(245, 245, 245);
            transition: all 0.3s ease-in-out;
            overflow: hidden;
            padding: .2rem .3rem;
            margin-bottom: 5.9rem;
            @include respond(lg){
                margin-bottom: 2.9rem;
                padding: .1rem;
            }
            @include respond(xs){
                margin-bottom: 2rem;
                padding: .1rem;
            }

            .switcher {
                position: absolute;
                border-radius: 52px;
                width: 50%;
                top: .1rem;
                height: 96%;
                overflow: hidden;
                background: rgb(224, 253, 96);
                transition: all .3s;
                @include respond(lg){
                    top: .1rem;
                    height: 94%;
                }
            }

            .item {
                position: relative;
                white-space: nowrap;
                padding: 1rem 2rem;
                border-radius: 5.2rem;
                cursor: pointer;
                align-items: center;
                display: flex;
                flex-wrap: nowrap;
                gap: 1rem;
                transition: all 0.3s ;

                .switch {
                    color: #757677 !important;
                }




            }
        }
    }

    &-category {
        padding: 4rem;
        background: #FFFFFF;
        border-radius: 5rem;
        margin-bottom: 4rem;
        @include respond(lg) {
            padding: 3rem;
            border-radius: 4rem;
        }

        @include respond(sm) {
            border-radius: 3rem;
        }

        @include respond(xs) {
            padding: 2rem;
            border-radius: 2rem;
        }
    }

    &-content {
        padding-bottom: 4.6rem;
    }

    &-menu {
        transition: all 0.5s ease;
        opacity: 0;
        visibility: hidden;
    }

    .tapped {
        opacity: 1;
        visibility: visible;
        transition: all 0.5s ease;
    }

    &__wrap {
        display: grid;
        grid-template-columns: repeat(2, .5fr);
        grid-gap: 0 4rem;
        padding-bottom: 6.6rem;
        transition: all 0.3s ease-in-out;
        @include respond(lg){
            grid-gap: 0 2rem;
        }

        @include respond(sm) {
            grid-gap: 1rem;
            padding-bottom: 6rem;
        }

        @include respond(xs){
            grid-template-columns: 100%;
        }



        &-column {
            display: grid;
            grid-template-columns: 1fr;
            grid-auto-rows:  auto;
            gap: 4rem;
            @include respond(lg){
               gap: 3rem;
            }
        }


    }

    &__item {
        position: relative;
        overflow: hidden;
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: 5rem;
        // cursor: url('../../../img/projectHover.svg'), default !important;

        @supports not (aspect-ratio: 1 / 1) {
            &::before {
                float: left;
                padding-top: 100%;
                content: "";
            }

            &::after {
                display: block;
                content: "";
                clear: both;
            }
        }

        @include respond(lg) {
            border-radius: 4rem;
        }

        @include respond(sm) {
            border-radius: 3rem;
        }

        @include respond(xs) {
            border-radius: 2rem;
        }

        &:hover {
            .projects__item-img, img {
                transform: scale(1.15);
            }
        }

        &-img-wrap {
            overflow: hidden;
            width: 100%;
            height: 100%;

            p {
                display: none;
            }

            iframe,
            img, video {
                transition: all 0.3s ease-in-out;
                width: 100%;
                display: block;
                height: 100%;
                object-fit: cover;
            }
        }

        &-img {
            display: block;
            transition: transform .4s;

        }
    }

    &-decription {
        display: flex;
        flex-direction: column;
        margin-right: 14.9rem;
        gap: .5rem;
        @include respond(lg) {
            margin-right: inherit;
            gap: .6rem;
        }
    }


}

.grid-main {
    display: grid;
    grid-template-columns: repeat(2, .5fr);
    grid-gap: 4rem;

    @include respond(sm) {
        grid-template-columns:100%;
    }
    .projects-menu :nth-child(2) {
        grid-column: 2;
    }
}

.loadMore {
    display: inline-block;
    align-items: center;
    background: #121212;
    color: #FFFFFF;
    border-radius: 9.4rem;
    padding: 1rem 2rem;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    &:hover {
        background-color: #434343;
    }
}

.activeItem {
    background: rgba(5, 5, 5, 1)!important;
    color: #FFFFFF !important;
}

.activeNum {
    background: rgba(224, 253, 96, 1) !important;
    color: #050505 !important;;
}
.activeTheme {
    transition: all .3s ease-out;
    background: rgba(5, 5, 5, 1);
    color: #FFFFFF;
}

.translateY {
    transform: translateY(-18.1rem);
    @include respond(lg){
        transform: translateY(-15.1rem);
    }

    @include respond(md) {
        transform: translateY(-18.9rem);
    }

    @include respond(sm) {
        transform: translateY(0);
    }
}


