.arrowBlock {
  pointer-events: none;
  opacity: 0;
  position: fixed;
  z-index: 1001;
}
.arrow-element {
  z-index: 1002;
  pointer-events: none;
}
.cursor__block {
  position: absolute;
  z-index: 1000;
  opacity: 1;
  visibility: visible;
  display: block;
}
.custom-circle-cursor {
  z-index: 10;
}
.cursor__none {
  cursor: none;
}
.cursor__block_hidden {
  opacity: 0;
  visibility: hidden;
  display: none;
}

.contacts-cursor {
  z-index: 5000;
}
