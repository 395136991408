@import '../../style/mixins';

.showreel {
    position: relative;
    height: 100%;
    cursor: none;
    .custom-cursor {
        width: 100px;
        height: 100px;
        position: absolute;
        pointer-events: none;
        z-index: 1000;
        background: url('../../img/videoPlayCursor.svg') no-repeat center center;
        backdrop-filter: blur(12.5px);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 50%;
        cursor: none;
    }

    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        font-size: 2rem;
        line-height: 2.6rem;

        @include respond(sm) {
            margin-bottom: 0.8rem;
        }

        span {
            margin-left: 0.5rem;
            opacity: 0.4;
        }

        &::before {
            content: '';
            width: 8px;
            height: 8px;
            margin-right: 0.8rem;
            border-radius: 50%;
            background: #FF4D01;
            transform: translateY(-.3rem);
        }
    }

    &__s {
        opacity: 1;
        //max-height: 83rem;
        // height: 100%;
        position: relative;
        cursor: none;

        &.pausing {
            height: 100%;
        }
        

        // &.pausing {
        //     cursor: url('../../img/videoPlayCursor.svg'), default !important;
        // }

        // &.playing {
        //     cursor: url('../../img/videoPauseCursor.svg'), default !important;
        // }

        @include respond(sm) {
            //max-height: 42rem;
        }

        video {
            width: 100%;
            object-fit: cover;
            //max-height: 83rem;
            height: 100%;

            @include respond(sm) {
                //max-height: 42rem;
            }
        }

        iframe {
            width: 100%;
            height: 100%;
            aspect-ratio: 1/0.5625;
        }

        p {
            display: none;
        }

        //@include respond(sm) {
        //    &::before {
        //        content: '';
        //        width: 6rem;
        //        height: 6rem;
        //        background: url('../../img/play-video.svg')no-repeat center/cover;
        //        position: absolute;
        //        top: 50%;
        //        left: 50%;
        //        transform: translate(-50%, -50%);
        //    }
        //}
    }
}
.popup-content {
    margin: auto;
    padding: 5px;
  }
  .popup-arrow {
    color: rgb(255, 255, 255);
  }
  [role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }
  
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  [data-popup='tooltip'].popup-overlay {
    background: transparent;
  }
.playIcon {
    position: relative;
}
.playIcon  video {
    width: 100%;
}

.playIcon iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 1/0.5625;
}

.rotate {
    display: none;
}
@media (max-width: 768px) {
    .rotate {
        display: block;
        position: absolute;
        z-index: 99;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 30px;

        &.playing {
            display: none;
        }
        &.pausing {
            display: block;
        }
    }   
}