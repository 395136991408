@import '../../style/_mixins.scss';

.section-social {
    overflow: hidden;
    &__wrap {
        padding: 9.5rem 0 7.1rem;
        display: grid;
        grid-template-columns: auto 74.5%;
        grid-gap: 5rem;
        justify-content: space-between;
        border-top: 1px solid rgba(25, 27, 29, 0.3);

        @include respond(md) {
            grid-template-columns: auto 78%;
            grid-gap: 2rem;
        }

        @include respond(sm) {
            padding: 6rem 0;
            grid-template-columns: 100%;
            grid-gap: 3rem;
        }
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(3, .33334fr);
        grid-gap: 4rem;

        @include respond(md) {
            grid-gap: 1rem;
        }

        @include respond(sm) {
            grid-template-columns: 100%;
            grid-gap: 1rem;
        }
    }

    &__item {
        padding: 4rem;
        position: relative;
        color: rgba(256, 256, 256, .5);
        transition: opacity .5s;

        &:hover {
            .hidden {
                opacity: 0;
            }

            .section-social__btn {
                opacity: 1;
                background: #FFFFFF;


            }
        }


        img {
            height: 100%;
        }

        @include respond(md) {
            padding: 3rem 2rem;
        }

        @include respond(sm) {
            padding: 3rem;
        }

        img {
            display: inline-block;
            height: 4rem;
            margin-bottom: 2rem;
        }
    }

    .hidden {
        opacity: 1;
        transition: opacity 0.5s;
    }

    &__descr {
        font-size: 2.4rem;
        line-height: 3.4rem;

        @include respond(sm) {
            font-size: 2rem;
            line-height: 2.6rem;
        }

    }

    &__btn {
        width: 5rem;
        height: 5rem;
        position: absolute;
        opacity: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        z-index: 3;

        transition: all .5s;

    }
    .arrow {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        width: 3rem;
        height: 0.25rem;
        border: none;
        border-radius: 1rem;
        transform-origin: right center;
        transition: all 0.2s;
        overflow: visible;
        transform: rotate(-45deg);
        background:  var(--custom-color);

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            width: 2rem;
            height: 2px;
            transform-origin: right center;
            transition: all 0.5s;
            background:  var(--custom-color);

        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}