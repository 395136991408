@import '../../style/_mixins.scss';

.footer {
    margin: 7rem 0 4rem;
    background: #121212;
    color: #FFFFFF;
    opacity: 1;
    transition: opacity 0.1s;
    z-index: 10;
    position: relative;

    @include respond(lg) {
        margin: 6rem 0 2rem;
    }

    @include respond(sm) {
        margin: 3rem 0 2rem;
    }

    .container {

        @include respond(sm) {
            padding: 0 2rem;
        }
    }
    .footer-frame {
        padding: 4.1rem 4rem;
        @include respond(lg) {
            padding: 3rem 3rem;
        }
        @include respond(xs) {
            padding: 2rem 2rem;
        }

        &__discuss {
            overflow: hidden;
            margin-top: 3rem;
           display: inline-block;
            padding: 1rem 2rem;
            background: rgb(224, 253, 96);
            color: #000000;
            border-radius: 9.4rem;

            @include respond(sm){
                margin-top: 2rem;
            }

            &-flex {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;

                img {
                    border-radius: 50%;
                    width: 2.4rem;
                    height: 2.4rem;
                    transition: all 0.3s;
                    transform: scale(1) translateX(0);
                    opacity: 1;
                }

                .text {
                    position: relative;
                    margin: 0 1rem;
                    transition: all 0.4s;

                    .icon {
                        position: absolute;
                        top: -.5rem;
                        left: auto;
                        right: -3.6rem;
                        width: 2.4rem;
                        height: 2.4rem;
                        transition: all 0.4s;
                        transform: scale(0);
                    }
                }


            }

            &:hover {
                img {
                    transform: scale(0) translateX(-2.4rem);
                    opacity: 0;

                }

                .text {
                    transform: translateX(-2.4rem);
                    .icon {
                        top: 0;
                        transform: scale(1);
                    }
                }


            }
        }
    }

    &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 13.8rem;
        position: relative;
        @include respond(lg) {
            margin-bottom: 8.8rem;
        }
        @include respond(sm) {
            margin-bottom: 4rem;
            grid-template-columns: 100%;
        }
        @include respond(xs) {
            margin-bottom: 3rem;
        }

    }


    &__wrap {
        display: grid;
        grid-template-columns: 1fr 0.5fr;
        grid-gap: 8.2rem;
        transition: opacity .4s;
        @include respond(lg) {
            grid-gap: 6rem;
        }
        @include respond(md) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 1.5rem;
        }

        @include respond(sm) {
            margin-top: 4rem;
        }

        @include respond(xs) {
            margin-top: 3rem;
            grid-template-columns: 100%;
            grid-gap: 1.5rem;
        }


    }

    &__subtitle {
        margin-bottom: 2rem;
        line-height: 1.8rem;
        opacity: 0.5;
    }

    &__item {
        margin-top: 1.1rem;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        .btn {
            margin-top: 1.9rem;
        }


        .flex-icon {
            display: flex;
            gap: 1rem;
        }

        .icon {
            width: 1.8rem;
            height: 1.8rem;
        }

        @include respond(sm) {
            margin-top: 0;
            gap: 2rem;
        }

        @include respond(xs) {
            gap: 1rem;
        }

        .contacts {
            color: rgba(224, 253, 96, 1);
            display: flex;
            flex-direction: column;
            gap: 1rem;

            transition: all 0.3s;

            p {
                transition: all 0.3s;
                &:hover{
                    opacity: 0.5;
                }
            }

            @include respond(sm) {
                gap: 0.3rem;
            }
            .footer__list {
                @include respond(sm) {
                    display: grid;
                    grid-template-columns: .5fr .5fr;
                    grid-column-gap: 2rem;
                }
            }
        }

    }

    &__contacts {

        &-item {
            display: block;
            font-weight: 500;
            font-size: 3.6rem;
            line-height: 4.3rem;
            transition: color .3s;

            @include respond(sm) {
                font-size: 3.2rem;
                line-height: 3.8rem;
            }

            &:not(:last-child) {
                margin-bottom: 1rem;

                @include respond(sm) {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }

    &__social {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;

        &:nth-child(2n) {
            margin-right: 0;
        }

        &-item {
            color: #fff;
            transition: color .4s, opacity 0.3s ease-in;
            position: relative;
                &:hover{
                    opacity: 0.5;
                }
        }
    }

    &__pages {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include respond(sm){
            margin-top: 0;
        }

        &:nth-child(2n) {
            margin-right: 0;
        }

        &-item {
            color: #fff;
            position: relative;

            a {
                transition: color .4s, opacity 0.3s ease-in;

                &:hover {
                    opacity: 0.5;
                }
            }

        }

        li {
            margin-bottom: 1.6rem;
            @include respond(sm) {
                margin-bottom: 1.5rem;

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }

    }

    &__list {
        &-item {
            transition: opacity .4s;
            font-weight: 500;
        }

        li.footer__list-item {
            display: table;

            &:hover {
                opacity: .5;
            }
        }
    }



    &-info{
        &__wrap {
            display: grid;
            grid-template-columns: 1fr 1fr;
            @include respond(xs) {
               display: flex;
                flex-direction: column-reverse;
                gap: 1rem;
            }
        }
    }
    &__copyright {
        p {
            opacity: 0.5;
        }
    }
}
