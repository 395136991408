.breadcrumbs {
    padding: 15rem 0;

    &__wrap {
        display: flex;
        align-items: center;
    }

    &__item {
        opacity: 0.6;
        line-height: 1.8rem;
        transition: color .4s;

        &:hover {
            color: #FF4D01;
        }

        &:not(:last-child) {
            margin-right: 1rem;

            &::after {
                content: '/';
                margin-left: 1rem;
            }
        }
    }
}