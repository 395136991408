.btn {
    padding: 1.4rem 4rem;
    display: inline-block;

    background: #3B3D3F;
    border-radius: 45px;
    border: none;
    cursor: pointer;

    font-size: 2rem;
    line-height: 2.6rem;
    color: #FFFFFF;

    transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

    @include respond(sm) {
        font-size: 1.6rem;
        line-height: 1.8rem;
        padding: 1.5rem 3rem;
    }

    &:hover {
        background: #FF4D01;
    }

    &.--b-orange {
        border: 1px solid #FF4D01;
        background: transparent;
        color: #FF4D01;

        &:hover {
            opacity: 0.5;
        }
    }

    &.--b-white {
        border: 1px solid #FFFFFF;
        color: #fff;
        background: transparent;

        &:hover {
            background: #FFFFFF;
            color: #121212;
        }
    }

    &.--orange {
        background: #FF4D01;

        &:hover {
            opacity: 0.5;
        }
    }

    &.--dark {
        background: #121212;

        &:hover {
            opacity: 0.5;
        }
    }

    &.--white {
        background: #FFFFFF;
        color: #191B1D;
        border: 1px solid #121212;

        &:hover {
            background: #121212;
            color: #fff;
        }
    }

    &.--circle {
        width: 15rem;
        height: 15rem;
        padding: 5.2rem 1.3rem;
        border-radius: 50%;
        background: none;
        border: none;
        text-align: center;
        will-change: transform;
        position: relative;

        @include respond(sm) {
            width: 13rem;
            height: 13rem;
            padding: 4.9rem .5rem 4.5rem;
        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
        }

        &.--orange {
            &::after {
                background: #FF4D01;
            }

            &:hover {
                opacity: 1;
            }
        }

        &.--dark {
            width: 20rem;
            height: 20rem;
            padding: 6.6rem 0.8rem;
            font-size: 2.4rem;
            line-height: 3.3rem ;
            font-weight: 400;
            &::after {
                background: #121212;
            }

            &:hover {
                opacity: 1;
            }
        }

        &.--b-white {
            &::after {
                border: 1px solid #FFFFFF;
            }
        }

        //&:hover {
        //    &::after {
        //        transform: scale(1.12);
        //    }
        //}
    }

    &.--grey {
        background: #F3F4F4;
        color: #191B1D;

        &:hover,
        &.active {
            background: #FF4D01;
            color: #FFFFFF;
        }
    }

    &.--black {
        font-size: 2rem;
        background: #121212;
        color: #FFFFFF;
        margin: 0;
        border: 1px solid #121212;

        &:hover {
            background: #FFFFFF;
            color: #121212;
        }

        @include respond(sm) {
            font-size: 1.6rem;
            display: inline-block;
        }
    }

    &.--footer {
        font-size: 2rem;
        background: transparent;
        font-weight: 400;
        border: 1px solid white;
        color: #FFFFFF;
        align-self: end;
        margin: 0;
        text-align: center;

        &:hover {
            background: #FFFFFF;
            color: #121212;
        }

        @include respond(sm) {
            font-size: 1.6rem;
            display: block;
            font-weight: normal;
            opacity: 0.8;
            align-self: inherit;
        }
    }
}

.black {
    color: #121212;
    display: block;
}

.fw {
    flex-wrap: wrap;
}

