@import '../../style/_mixins.scss';

.section-products {
    padding: 10rem 0;
    background: #F3F4F4;

    @include respond(sm) {
        padding: 6rem 0;
    }

    &__wrap {
        display: grid;
        grid-template-columns: auto 74.5%;
        grid-gap: 5rem;
        justify-content: space-between;

        @include respond(md) {
            grid-template-columns: auto 78%;
            grid-gap: 2rem;
        }

        @include respond(sm) {
            grid-template-columns: 100%;
            grid-gap: 3rem;
        }
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(2, .5fr);
        grid-gap: 4rem;

        @include respond(md) {
            grid-gap: 1rem;
        }

        @include respond(sm) {
            grid-template-columns: 100%;
            grid-gap: 3rem;
        }
    }

    &__item {
        display: block;
        position: relative;

        &:hover {
            .section-products__text {
                opacity: 1;
            }

            .section-products__btn {
                background: #FF4D01;
            }
        }

        img{
            height: 100%;
        }
    }

    &__video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__frame {
        width: 100%;
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    &__text {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 2rem;

        background: #FFFFFF;
        opacity: 0;
        text-align: center;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        transition: opacity .5s;

        @include respond(sm) {
            padding: 2rem;
        }
    }

    &__name {
        margin-bottom: 1rem;
        font-weight: 500;
        font-size: 3.6rem;
        line-height: 4.3rem;

        @include respond(sm) {
            font-size: 2.8rem;
            line-height: 3.2rem;
        }
    }

    &__descr {
        max-width: 42rem;
        font-size: 2.4rem;
        line-height: 3.4rem;

        @include respond(sm) {
            font-size: 1.8rem;
            line-height: 2.2rem;
        }
    }

    &__btn {
        width: 5rem;
        height: 5rem;

        background: rgba(255, 255, 255, 0.1);
        border-radius: 38px;

        position: absolute;
        bottom: 3rem;
        right: 3rem;
        z-index: 3;

        transition: all .5s;

        @include respond(sm) {
            width: 4rem;
            height: 4rem;
            right: 1rem;
            bottom: 1rem;
        }

        &::before,
        &::after {
            content: '';
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &::before {
            width: 1.7rem;
            height: 2px;
        }

        &::after {
            height: 1.7rem;
            width: 2px;
        }
    }
}
