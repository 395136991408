@import '../../../style/mixins';

.services {
    &-s {
        padding: 13.4rem 0 4rem;
        color: #050505;
        position: relative;
        overflow: hidden;

        @include respond(sm){
            padding: 11.4rem 0 3rem;
        }

        &__text{
        }

        &__wrap {
            display: grid;
            grid-template-columns: .25fr .75fr;
            grid-gap: 4rem;

            @include respond(sm) {
                grid-template-columns: 100%;
                grid-gap: 3rem;
            }
        }

        &__subtitle {
            margin-bottom: 3rem;
            font-weight: 500;
            font-size: 2.8rem;
            line-height: 3.4rem;

            @include respond(sm) {
                margin-bottom: 2rem;
                font-size: 2.6rem;
                line-height: 3.1rem;
            }
        }

        &__dir {
            display: flex;
            align-items: center;

            &-img {
                width: 6rem;
                height: 6rem;
                flex: 0 0 6rem;
                margin-right: 2rem;
                border-radius: 50%;

                @include respond(sm) {
                    width: 4rem;
                    height: 4rem;
                    flex: 0 0 4rem;
                    margin-right: 1.5rem;
                }
            }

            &-name {
                font-size: 2rem;
                line-height: 2.6rem;

                @include respond(sm) {
                    font-size: 1.6rem;
                    line-height: 1.8rem;
                }
            }
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(2, .5fr);
            grid-gap: 2rem;
            overflow: hidden;

            @include respond(sm) {
                grid-template-columns: 100%;
                grid-gap: 1rem;
            }
        }

        &__item {
            padding: 4rem;
            background: #F3F4F4;
            position: relative;
            transition: all .5s;

            @include respond(sm) {
                padding: 3rem;
            }

            &:hover {
                background: #FF4D01;
                color: #fff;

                .services-s__icon {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

        &__icon {
            width: 5rem;
            height: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: #FFFFFF;
            position: absolute;
            right: 2rem;
            top: 2rem;
            opacity: 0;
            transform: scale(0);
            transition: transform 0.5s cubic-bezier(0.25, 1.75, 0.75, 0.9);

            @include respond(sm) {
                width: 3rem;
                height: 3rem;
                right: 1rem;
                top: 1rem;
            }
        }
    }

    &-about {
        position: relative;
        margin-bottom: 7rem;
        overflow: hidden;
        background-color: #FFFFFF;

        @include respond(lg){
            margin-bottom: 6rem;
        }

        @include respond(sm) {
            margin-bottom: 4rem;
        }

        @include respond(xs){
            margin-bottom: 3rem;
        }

        &__wrap {
            display: grid;
            grid-template-columns: 1fr 1fr;
            @include respond(xs){
                grid-template-columns: 1fr;
                gap: 2rem;
            }

            .info {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                @include respond(sm){
                    gap: 1rem;
                }

                &__descr {
                    color: #757677;
                }

            }
        }


        &__adv {
            &-item {
                display: flex;
                font-weight: 500;
                align-items: center;
                font-size: 2.4rem;
                line-height: 3.36rem;

                @include respond(sm) {
                    font-size: 2rem;
                    line-height: 2.6rem;
                }

                &:not(:last-child) {
                    margin-bottom: 3rem;

                    @include respond(sm) {
                        margin-bottom: 2rem;
                    }
                }

                .icon {
                    width: 5rem;
                    height: 5rem;
                    margin-right: 1.5rem;

                    @include respond(sm) {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }

    &-list {
        margin-bottom: 7rem;
        position: relative;
        color: #FFFFFF;
        @include respond(lg){
            margin-bottom: 6rem;
        }

        @include respond(sm) {
            margin-bottom: 4rem;
        }

        @include respond(xs){
            margin-bottom: 3rem;
        }

        &__wrapp {
            display: flex;
            flex-direction: column;
            gap: 4rem;
            background: #050505;
            @include respond(xs){
                gap: 2rem;
            }

            &-item {
                border-bottom: 1px solid #757677;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                padding-bottom: 4rem;
                @include respond(sm){
                    padding-bottom: 3rem;
                }
                @include respond(xs){
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    padding-bottom: 2rem;
                }

            .describe {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                @include respond(xs){
                    gap: 1rem;
                }

                &__wrapp {
                    max-width: 70%;
                    width: 100%;
                    @include respond(sm){
                        max-width: 80%;
                    }
                    @include respond(xs){
                        max-width: 100%;
                    }
                    &-benefits {
                       display: flex;
                        flex-direction: column;

                        &__item{
                            display: grid;
                            grid-template-columns: auto 1fr;
                            gap: 2rem;

                            @include respond(sm){
                                gap: 1rem;
                            }
                            .num{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                background-color: #E0FD60;
                                color: #050505;
                                border-radius: 3.9rem;
                                width: 4.2rem;
                                aspect-ratio: 1 / 1;
                                border: none;
                                @supports not (aspect-ratio: 1 / 1) {
                                    &::before {
                                        float: left;
                                        padding-top: 100%;
                                        content: "";
                                    }

                                    &::after {
                                        display: block;
                                        content: "";
                                        clear: both;
                                    }
                                }

                                @include respond(sm){
                                    width: 4rem;
                                }
                            }
                            &:not(:last-child) {
                                padding-bottom: 1.5rem;
                                margin-bottom: 1.5rem;
                                border-bottom: 1px solid rgb(117, 118, 119);
                            }

                            .tariffs{
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);
                                align-items: center;
                                @include respond(sm){
                                    grid-template-columns: 2fr 1fr
                                }

                                @include respond(xs){
                                    grid-template-columns:100%
                                }

                                .price{
                                    color: rgba(117, 118, 119, 1);

                                    @include respond(lg){
                                        text-align: end;
                                        margin-right: 1.5rem;
                                    }

                                    @include respond(sm) {
                                        text-align: start;
                                        margin-right: 0;
                                    }

                                }

                                &__span {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    flex-wrap: nowrap;
                                    gap: 2rem;
                                    @include respond(sm){
                                        gap: 1rem;
                                    }

                                }
                            }
                        }



                    }
                }

            }

            }

        }
    }

    &-principle {
        position: relative;
        background: #050505;
        color: white;
        margin-bottom: 7rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @include respond(lg){
            margin-bottom: 6rem;
        }

        @include respond(sm) {
            margin-bottom: 4rem;
        }

        @include respond(xs){
            grid-template-columns: 100%;
            gap: 2rem;
            margin-bottom: 3rem;
        }


        &__wrap {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 3rem;
            position: relative;

            @include respond(sm) {
                grid-template-columns: 100%;
                grid-gap: 3rem;
            }

            &-item {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 4rem;

                @include respond(lg){
                    gap: 2rem;
                }

                p:nth-child(2){
                    color: #757677;
                    margin-top: 1rem;

                    @include respond(xs){
                        margin-top: .5rem;
                    }
                }

                img {
                    width: 10rem;
                    height: 10rem;
                    @include respond(lg) {
                        width: 7rem;
                        height: 7rem;
                    }

                    @include respond(xs) {
                        width: 5rem;
                        height: 5rem;
                    }
                }
            }
        }
    }

    &-team {
        position: relative;
        background-color: white;

        &__wrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            @include respond(xs) {
                grid-template-columns: 100%;
            }

            .info{
                display: flex;
                flex-direction: column;
                gap: 1rem;

                @include respond(xs){
                    margin-bottom: 2rem;
                }

                .intro {
                    width: 100%;
                    max-width: 35.6rem;
                    margin-right: auto;
                    color: #757677;

                    @include respond(sm){
                        max-width: 25.2rem;
                    }
                }
            }

        }

        &__wrapper {
            display: grid;
            grid-template-columns: repeat(2, 0.5fr);
            grid-gap: 2rem 0 ;
            padding: 3rem 0 2rem;
            @include respond(sm) {
                grid-template-columns: 100%;
                padding: 2rem 0;
            }

            @include respond(xs){
                grid-gap: 1rem 0 ;
            }

            .worker {
                display: flex;
                align-items: center;
                gap: 1.5rem;

                span {
                    p:nth-child(2){
                        color: #757677;
                    }
                }

                &-name {

                }
                &-img {
                    border: 1px solid #FFFFFF;
                    border-radius: 50%;
                    width: 5rem;
                    height: 5rem;

                    @include respond(lg) {
                        width: 4rem;
                        height: 4rem;
                    }

                }

                &-descr {
                    font-size: 1.6rem;
                    padding-bottom: 0;
                    @include respond(sm) {
                        font-size: 1.6rem;
                    }
                }
            }

        }

        &__table {
            border-top: 1px solid #D9D9D9;

            .item {
                display: grid;
                grid-template-columns: repeat(2, 0.5fr);
                grid-gap: 2rem 0 ;
                padding: 1.5rem 0;
                border-bottom: 1px solid #D9D9D9;
                @include respond(sm) {
                    grid-template-columns: 3fr 1fr;
                    padding: 1rem 0;
                   grid-gap:0 ;
                } @include respond(xs) {
                    grid-template-columns: 1fr;
                    padding: 1rem 0;
                   grid-gap:0 ;
                }

                p:nth-child(2){
                    color: #757677;
                }
            }
        }


    }

}

